<div class="modal-dialog modal-notify modal-info modalSeccionBloqueada">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="heading lead">Sección bloqueada</h1>
			<button [mat-dialog-close] type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true" class="text-white">&times;</span>
			</button>
		</div>
		<div mat-dialog-content class="modal-body">
			<div class="text-center">
				<i class="fas fa-lock fa-4x animated rotateIn mb-4"></i>
				<p>Introduzca contraseña<br />para desbloquear</p>
				<p *ngIf="incorrecta" class="passIncorrecta">Contraseña incorrecta</p>
				<input
					type="password"
					class="form mr-2 mb-1"
					id="password"
					name="password"
					[(ngModel)]="password"
					(keydown.enter)="onAceptar($event)"
					autocomplete="off"
				/>
				<a type="button" class="btn btn-success" (click)="onAceptar($event)" (keypress)="onAceptar($event)" tabindex="0"
					><i class="fas fa-check fa-lg text-white"></i> ACEPTAR</a
				>
			</div>
		</div>
	</div>
</div>
