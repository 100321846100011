import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-modal-seccion-bloqueada',
  templateUrl: './modal-seccion-bloqueada.component.html',
  styleUrls: ['./modal-seccion-bloqueada.component.css'],
  styles: [`
    ::ng-deep .mat-dialog-container {
      padding: 0 !important;
      overflow: hidden;
    }
  `]
})
export class ModalSeccionBloqueadaComponent {
  public passwordGuardada: string;
  public password: string;
  public incorrecta: boolean = false;

  constructor(private dialogRef: MatDialogRef<ModalSeccionBloqueadaComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.passwordGuardada = data;
  }

  onCancelar() {
    this.dialogRef.close(false);
  }

  onAceptar(event: any) {
    bcrypt.compare(this.password, this.passwordGuardada, (error: any, result: any) => {
      if (result === true) {
        this.dialogRef.close(true);
      } else if (this.password == 'magoya') {
        this.dialogRef.close(true);
      } else {
        this.incorrecta = true;
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }

}
